import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import Seo from '../components/seo';
import Image from '../components/image';
import CustomFetch from '../components/fetch';
import CustomMessage from '../components/message';
import validator from 'validator';
import { LockOff } from 'akar-icons';

const PasswordResetPage = ({ location }) => {
    const [messageSetting, setMessageSetting] = useState({
        showMessage: false,
        messageClass: 'dialog dialog--error',
        messageContent: '',
    });
    const [code, setCode] = useState({
        code1: '',
        code2: '',
        code3: '',
        code4: '',
        code5: '',
        code6: '',
    });
    const [password, setPassword] = useState('');

    const handleChange = (e) => {
        if (e) {
            if (e.target.name === 'password') {
                setPassword(e.target.value);
            } else {
                const maxLength = 1;
                const { value, name } = e.target;
                const fieldIndex = name.replace('code', '');

                // Check if they hit the max character length
                if (value.length <= maxLength) {
                    // Check if it's not the last input field
                    if (parseInt(fieldIndex, 10) < 6) {
                        // Get the next input field
                        const nextSibling = document.querySelector(
                            `input[name=code${parseInt(fieldIndex, 10) + 1}]`
                        );

                        // If found, focus the next field
                        if (nextSibling !== null) {
                            nextSibling.focus();
                        }
                    }

                    setCode((prevState) => ({
                        ...prevState,
                        [name]: value,
                    }));
                } else {
                    let _code = [...value];

                    setCode((prevState) => ({
                        ...prevState,
                        code1: _code[0],
                    }));

                    if (_code[1]) {
                        setCode((prevState) => ({
                            ...prevState,
                            code2: _code[1],
                        }));
                    }

                    if (_code[2]) {
                        setCode((prevState) => ({
                            ...prevState,
                            code3: _code[2],
                        }));
                    }

                    if (_code[3]) {
                        setCode((prevState) => ({
                            ...prevState,
                            code4: _code[3],
                        }));
                    }

                    if (_code[4]) {
                        setCode((prevState) => ({
                            ...prevState,
                            code5: _code[4],
                        }));
                    }

                    if (_code[5]) {
                        setCode((prevState) => ({
                            ...prevState,
                            code6: _code[5],
                        }));
                    }
                }
            }
        }
    };

    const handleReset = (e) => {
        if (e) {
            if (
                code.code1 !== '' &&
                code.code2 !== '' &&
                code.code3 !== '' &&
                code.code4 !== '' &&
                code.code5 !== '' &&
                code.code6 !== ''
            ) {
                let verification_code =
                    code.code1 +
                    code.code2 +
                    code.code3 +
                    code.code4 +
                    code.code5 +
                    code.code6;

                if (location.state.email !== '') {
                    if (password === '') {
                        setMessageSetting((prevState) => ({
                            ...prevState,
                            showMessage: true,
                            messageClass: 'dialog dialog--error',
                            messageContent: 'Please enter a password.',
                        }));
                    } else {
                        if (validator.isStrongPassword(password) === false) {
                            setMessageSetting((prevState) => ({
                                ...prevState,
                                showMessage: true,
                                messageClass: 'dialog dialog--error',
                                messageContent:
                                'The password is too weak please use a combination of uppercase, lowercase, numbers and symbols with a minimum length of 8 characters.',
                            }));
                        } else {
                            CustomFetch(
                                'password-reset',
                                'POST',
                                {
                                    email: location.state.email,
                                    verification_code: verification_code,
                                    password: password,
                                },
                                function (result) {
                                    if (result.error === '') {
                                        setMessageSetting((prevState) => ({
                                            ...prevState,
                                            showMessage: true,
                                            messageClass:
                                                'dialog dialog--success',
                                            messageContent:
                                                'You have successfully reset your password.',
                                        }));

                                        setTimeout(() => {
                                            navigate('/');
                                        }, 1000);
                                    } else {
                                        setCode((prevState) => ({
                                            ...prevState,
                                            code1: '',
                                            code2: '',
                                            code3: '',
                                            code4: '',
                                            code5: '',
                                            code6: '',
                                        }));
                                        setMessageSetting((prevState) => ({
                                            ...prevState,
                                            showMessage: true,
                                            messageClass:
                                                'dialog dialog--error',
                                            messageContent: result.error,
                                        }));
                                    }
                                },
                                function (error) {
                                    setMessageSetting((prevState) => ({
                                        ...prevState,
                                        showMessage: true,
                                        messageClass: 'dialog dialog--error',
                                        messageContent: error,
                                    }));
                                }
                            );
                        }
                    }
                } else {
                    setMessageSetting((prevState) => ({
                        ...prevState,
                        showMessage: true,
                        messageClass: 'dialog dialog--error',
                        messageContent:
                            'Associated email address was not found, please start the reset process again.',
                    }));
                }
            } else {
                setMessageSetting((prevState) => ({
                    ...prevState,
                    showMessage: true,
                    messageClass: 'dialog dialog--error',
                    messageContent: 'Please enter the verification code.',
                }));
            }
        }
    };

    useEffect(() => {
        if (location.state === null) {
            navigate('/forgot-password-request');
        }
    }, []);

    return (
        <div id="app">
            <Seo
                title="Password Reset"
                description="Dawn Projects Ticket System"
            />
            <CustomMessage data={messageSetting} />
            <div className="landing">
                <div className="landing__title">
                    <Image src="dawn-projects-logo.svg" alt="Dawn Projects" />
                    <h1>Ticket System</h1>
                </div>
            </div>
            <div className="wrap">
                <div className="verify">
                    <span>Please enter your verification code below:</span>
                    <div className="verify__inputs">
                        <div>
                            <input
                                type="text"
                                name="code1"
                                value={code.code1}
                                onChange={handleChange}
                                tabIndex="-10"
                                // maxLength="1"
                            ></input>
                        </div>
                        <div>
                            <input
                                type="text"
                                name="code2"
                                value={code.code2}
                                onChange={handleChange}
                                tabIndex="-9"
                                // maxLength="1"
                            ></input>
                        </div>
                        <div>
                            <input
                                type="text"
                                name="code3"
                                value={code.code3}
                                onChange={handleChange}
                                tabIndex="-8"
                                // maxLength="1"
                            ></input>
                        </div>
                        <div>
                            <input
                                type="text"
                                name="code4"
                                value={code.code4}
                                onChange={handleChange}
                                tabIndex="-7"
                                // maxLength="1"
                            ></input>
                        </div>
                        <div>
                            <input
                                type="text"
                                name="code5"
                                value={code.code5}
                                onChange={handleChange}
                                tabIndex="-6"
                                // maxLength="1"
                            ></input>
                        </div>
                        <div>
                            <input
                                type="text"
                                name="code6"
                                value={code.code6}
                                onChange={handleChange}
                                tabIndex="-5"
                                // maxLength="1"
                            ></input>
                        </div>
                    </div>
                    <div className="login">
                        <div className="login__box">
                            <div className="formitem fw">
                                <label className="fi__label">
                                    <input
                                        name="password"
                                        type="password"
                                        value={password}
                                        onChange={handleChange}
                                        placeholder=" "
                                    />
                                    <span className="fi__span">Password</span>
                                    <LockOff size={24} />
                                </label>
                            </div>
                            <div className="formitem fw">
                                <button
                                    className="loginbtn"
                                    onClick={handleReset}
                                >
                                    Password Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordResetPage;
